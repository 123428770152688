//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import requisitesTableConfig from '@/views/TradersRequisitesAndDevices/Requisites/tableConfig';
import Filters from './TableFilters.vue';
import User from '~/components/User.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';
import constant from '~/const';

const validExtensions = [
  'doc',
  'docx',
  'docm',
  'txt',
  'jpeg',
  'jpg',
  'png',
  'xls',
  'xlsx',
  'xlsm',
  'xml',
  'pdf',
  'heif',
  'heic',
];

const validImages = ['jpeg', 'jpg', 'png'];

const replaceEnter = (val, replaceVal = ' ') => {
  return val.replace(/\r?\n/g, replaceVal);
};

function countTextareaLines(textarea) {
  let { _buffer } = textarea;
  if (_buffer == null) {
    // eslint-disable-next-line
    textarea._buffer = document.createElement('textarea');
    // eslint-disable-next-line
    _buffer = textarea._buffer;
    _buffer.style.border = 'none';
    _buffer.style.height = '0';
    _buffer.style.overflow = 'hidden';
    _buffer.style.padding = '0';
    _buffer.style.position = 'absolute';
    _buffer.style.left = '0';
    _buffer.style.top = '0';
    _buffer.style.zIndex = '-1';
    document.body.appendChild(_buffer);
  }

  const cs = window.getComputedStyle(textarea);
  // eslint-disable-next-line
  const pl = parseInt(cs.paddingLeft);
  // eslint-disable-next-line
  const pr = parseInt(cs.paddingRight);
  // eslint-disable-next-line
  let lh = parseInt(cs.lineHeight);

  // [cs.lineHeight] may return 'normal', which means line height = font size.
  // eslint-disable-next-line
  if (isNaN(lh)) lh = parseInt(cs.fontSize);

  // Copy content width.
  // eslint-disable-next-line
  _buffer.style.width = textarea.clientWidth - pl - pr + 'px';

  // Copy text properties.
  _buffer.style.font = cs.font;
  _buffer.style.letterSpacing = cs.letterSpacing;
  _buffer.style.whiteSpace = cs.whiteSpace;
  _buffer.style.wordBreak = cs.wordBreak;
  _buffer.style.wordSpacing = cs.wordSpacing;
  _buffer.style.wordWrap = cs.wordWrap;

  // Copy value.
  _buffer.value = textarea.value;

  let result = Math.floor(_buffer.scrollHeight / lh);
  // eslint-disable-next-line
  if (result == 0) result = 1;
  return result;
}

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default {
  components: { Filters, User },

  data() {
    return {
      tableConfig,
      actionsConfig,
      requisitesTableConfig,
      selection: [],
      chat: {
        modal: false,
        data: [],
        currentChatId: null,
        textValue: '',
        fileInput: null,
        messageRowsCount: 1,
      },
      loader: false,
      constant,
      getMessagesTimerId: null,
    };
  },

  // SINGLE TABLE COMPONENT
  async mounted() {
    // const { page } = this.$route.query;
    // const numberPage = Number(page);
    // if (numberPage) {
    //   this.setPageAction(numberPage);
    // }

    // this.setGeneralLoader(true);
    // await this.loadData();
    // this.setGeneralLoader(false);
    this.getMessagesTimerId = setInterval(async () => {
      if (this.chat.modal && this.chat.currentChatId) {
        this.setGeneralProgress(true);
        await this.getChatMessagesAction({
          GroupChatId: this.chat.currentChatId,
          Limit: this.sortedByDateChatMessages.length || 20,
          GetTotal: true,
        });
        this.setGeneralProgress(false);
      }
    }, 10 * 1000);
  },

  beforeDestroy() {
    if (this.getMessagesTimerId) {
      clearInterval(this.getMessagesTimerId);
    }
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersDeals', ['page', 'pages', 'data', 'sort', 'chatMessages', 'filesData', 'chatMessagesTotal']),
    ...mapGetters('tradersDeals', ['sortedByDateChatMessages']),

    errorFileMessage() {
      if (!this.fileMaxSizeValidation) {
        return 'fileSizeError';
      }
      if (!this.fileExtensionsValidation) {
        return 'fileFormatError';
      }
      return '';
    },

    fileName() {
      return this.chat.fileInput?.name;
    },

    fileInfo() {
      const name = (this.fileName?.length || '') > 23 ? `${this.fileName?.slice(0, 20)}…` : this.fileName.value;
      return `${name} (${this.fileSizeMegabytes?.toFixed(2)} Mb)`;
    },

    fileSizeMegabytes() {
      return this.chat.fileInput?.size ? this.chat.fileInput.size / 1e6 : null;
    },

    fileExtensionsValidation() {
      const format = this.chat.fileInput?.name.split('.').pop();

      return format ? validExtensions.includes(format.toLowerCase()) : false;
    },

    fileMaxSizeValidation() {
      return !(this.fileSizeMegabytes && this.fileSizeMegabytes > 2);
    },

    disabledSendMessage() {
      if (this.chat.fileInput) {
        return !this.fileExtensionsValidation || !this.fileMaxSizeValidation;
      }
      return !this.chat.textValue;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('tradersDeals', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      getChatMessagesAction: 'getChatMessages',
      appealDownloadFile: 'appealDownloadFile',
      appealGetFile: 'appealGetFile',
      sendChatMessage: 'sendChatMessage',
    }),
    ...mapActions('tradersOffers', {
      setOffersFilters: 'setFilters',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    async showChat(GroupChatId) {
      this.setGeneralProgress(true);
      try {
        await this.getChatMessagesAction({
          GroupChatId,
          Limit: 20,
          GetTotal: true,
        });
        this.chat.currentChatId = GroupChatId;
        this.chat.modal = true;
      } catch (error) {
        this.setErrorNotification('Trade chat does not exist');
      }
      this.setGeneralProgress(false);
    },

    async loadMoreMessages() {
      this.setGeneralProgress(true);
      await this.getChatMessagesAction({
        GroupChatId: this.chat.currentChatId,
        Limit: this.sortedByDateChatMessages.length + 20,
        GetTotal: true,
      });
      this.setGeneralProgress(false);
    },

    async getFileData(file) {
      this.setGeneralProgress(true);
      const { data } = await this.appealGetFile(file);
      this.setGeneralProgress(false);
      if (data?.result?.success) {
        return data.data;
      }

      return '';
    },

    getFile(file) {
      this.appealDownloadFile(file);
    },

    fileIsImage(file) {
      return validImages.includes(file.extension);
    },

    deleteFileInput() {
      this.chat.fileInput = null;
    },

    handleFileInput(event) {
      this.chat.fileInput = event;
    },

    openFileInput() {
      if (this.$refs.fileInputRef) {
        const input = this.$refs.fileInputRef.$el.querySelector('input');
        input.click();
      }
    },

    getLength(str) {
      return str.length;
    },

    handleInput() {
      const textarea = this.$refs.base.$el.querySelector('textarea');
      this.chat.messageRowsCount = countTextareaLines(textarea);
    },

    async sendMessageHandler(e) {
      this.setGeneralProgress(true);
      if (!this.fileMaxSizeValidation || this.disabledSendMessage.value) {
        return;
      }

      e.preventDefault();
      const value = replaceEnter(this.chat.textValue);
      if (this.chat.fileInput) {
        const base64File = await convertBase64(this.chat.fileInput);
        await this.sendChatMessage({
          groupChatId: this.chat.currentChatId,
          message: value,
          files: [{ data: base64File, file: this.chat.fileInput }],
        });
      } else {
        await this.sendChatMessage({ groupChatId: this.chat.currentChatId, message: value });
      }
      await this.getChatMessagesAction({
        GroupChatId: this.chat.currentChatId,
        Limit: 20,
        GetTotal: true,
      });
      this.chat.textValue = '';
      this.chat.fileInput = null;
      this.chat.messageRowsCount = 1;
      this.setGeneralProgress(false);
    },

    prepareText(text) {
      if (!text) return '';

      const preparedText = text
        .replaceAll('<b>', '')
        .replaceAll('</b>', '')
        .replaceAll('<br>', '');
      return preparedText;
    },

    prepareTextArray(text) {
      if (!text) return [];
      const preparedText = text.replaceAll('<br>', '');
      const arr = preparedText.split(/<b>|<\/b>/);
      if (preparedText.indexOf('<b>') === 0) arr.unshift('');
      return arr;
    },

    async openOfferById(offerId) {
      this.setOffersFilters({ id: offerId });
    },

    statusClass(status) {
      return constant.traders.REQUISITE_TYPE[status] === constant.traders.REQUISITE_TYPE.REQUISITE_STATUS_ENABLED
        ? 'success--text'
        : 'error--text';
    },

    paymentMethodStatusClass(status) {
      return status === constant.traders.PAYMENT_METHOD_STATUS.ENABLED
        ? 'success--text'
        : 'error--text';
    },
  },
};
