//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
    isMassUpdating: { type: Boolean, default: false },
  },

  data() {
    return {
      input: {},
      constant,
    };
  },

  computed: {
    ...mapState('tradersRequisites', ['userData']),

    userRequisites() {
      // TODO replace with !this.data.userId after the userId field is added to the offer object in the response
      if (!this.data?.requisites?.[0]?.userId) {
        return [];
      }
      // TODO replace with this.data.userId after the userId field is added to the offer object in the response
      return this.userData[this.data.requisites[0].userId];
    },

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    defaultInput() {
      return {
        maxLimit: 0,
        minLimit: 0,
        status: 0,
        requisiteIds: [],
      };
    },
    isApplyPossible() {
      return this.input?.maxLimit
      && this.input?.minLimit
      && (this.input?.status || this.input?.status === 0)
      && this.input?.requisiteIds?.length;
    },
  },

  watch: {
    value(val) {
      if (val) {
        const {
          isCreating, defaultInput, data, isMassUpdating,
        } = this;
        if (isCreating || isMassUpdating) {
          this.$set(this, 'input', defaultInput);
        } else {
          const model = { offerId: data.id };
          Object.keys(defaultInput).forEach((key) => {
            if (key === 'status') {
              model.status = this.constant.traders.OFFER_STATUS[data.status];
            } else {
              model[key] = data[key];
            }
          });
          model.requisiteIds = data.requisites.map((requisite) => requisite.id);
          this.$set(this, 'input', model);
        }
      }
    },
  },

  methods: {
    apply() {
      const { input } = this;
      this.$emit('apply', { input });
    },
  },
};
