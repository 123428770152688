//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Deals from '~/views/TradersOffersAndDeals/Deals/Table.vue';
import Offers from '~/views/TradersOffersAndDeals/Offers/Table.vue';

export default {
  components: {
    Deals,
    Offers,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadDeals(),
      this.loadOffers(),
      this.loadCurrencies(),
      this.loadPaymentMethods(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('tradersDeals', {
      loadDeals: 'loadData',
    }),
    ...mapActions('tradersOffers', {
      loadOffers: 'loadData',
    }),
    ...mapActions('tradersCurrencies', {
      loadCurrencies: 'loadData',
    }),
    ...mapActions('tradersPaymentMethods', {
      loadPaymentMethods: 'loadData',
    }),
  },
};
