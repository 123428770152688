//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import requisitesTableConfig from '@/views/TradersRequisitesAndDevices/Requisites/tableConfig';
import constant from '~/const';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';
import User from '~/components/User.vue';
import DropdownMenu from '~/components/ui/dropdown-menu/Index.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: {
    Filters, Modal, User, DropdownMenu,
  },

  data() {
    return {
      editingOffer: {
        show: false,
        data: {},
        isCreating: false,
      },
      massEditingOffers: {
        show: false,
      },
      tableConfig,
      actionsConfig,
      requisitesTableConfig,
      selection: [],
      loader: false,
      constant,
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersOffers', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersOffers', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editOfferAction: 'editOffer',
      editMassOffersAction: 'editMassOffers',
    }),
    ...mapActions('tradersRequisites', ['loadRequisitesByUserId']),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      if (this.$refs.offersTable?.$el?.scrollIntoView) {
        this.$refs.offersTable.$el.scrollIntoView();
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openModalEditOffer(row) {
      this.editingOffer.show = true;
      this.editingOffer.data = { ...row };
      this.editingOffer.isCreating = false;

      // TODO replace with row.userId after the userId field is added to the offer object in the response
      if (row.requisites?.[0]?.userId) {
        const currency = row.FlowType === constant.traders.FLOW_TYPE.BUY ? row.giveCurrency : row.getCurrency;

        // TODO replace with row.userId after the userId field is added to the offer object in the response
        this.loadRequisitesByUserId({
          userId: row.requisites[0].userId,
          status: constant.traders.OFFER_STATUS.OFFER_STATUS_ENABLED,
          currency,
        });
      }
    },

    applyEdit({ input }) {
      const { isCreating } = this.editingOffer;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create offer?'
          : 'Are you sure you want to edit offer?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (isCreating) {
              // There is no POST method of creating Offer
            } else {
              await this.editOfferAction(input);
            }
            this.editingOffer.show = false;
            await this.loadDataAction({});
            if (isCreating) {
              this.setSuccessNotification('Offer created');
            } else {
              this.setSuccessNotification('Offer edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    updateOffers() {
      const { selection } = this;
      if (selection.length === 0) {
        this.setErrorNotification('No offers selected');
        return;
      }

      this.massEditingOffers.show = true;
    },

    applyMassEdit({ input }) {
      const payload = this.selection.map((offerId) => {
        const { status, minLimit, maxLimit } = input;
        return {
          offerId, status, minLimit: Number(minLimit), maxLimit: Number(maxLimit),
        };
      });
      this.confirmAction({
        title: 'Are you sure you want to mass edit offers?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.editMassOffersAction({ offers: payload });
            this.massEditingOffers.show = false;
            await this.loadDataAction({});
            this.setSuccessNotification('Offers edited');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    statusClass(status) {
      let res = '1';
      switch (constant.traders.OFFER_STATUS[status]) {
        case constant.traders.OFFER_STATUS.OFFER_STATUS_ENABLED: res = 'success--text';
          break;
        case constant.traders.OFFER_STATUS.OFFER_STATUS_DELETED:
        case constant.traders.OFFER_STATUS.OFFER_STATUS_BLOCKED:
          res = 'error--text';
          break;
        case constant.traders.OFFER_STATUS.OFFER_STATUS_STOPPED: res = 'warning--text';
          break;
        default:
          res = 'success--text';
          break;
      }
      return res;
    },

    paymentMethodStatusClass(status) {
      return status === constant.traders.PAYMENT_METHOD_STATUS.ENABLED
        ? 'success--text'
        : 'error--text';
    },
  },
};
