export default [
  {
    width: 20,
    header: {
      type: 'slot',
      name: 'customActions',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Get currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.getCurrency,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Give currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.giveCurrency,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Flow type',
    },
    cell: {
      type: 'slot',
      name: 'flowType',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Balance of requisites',
    },
    cell: {
      type: 'caption',
      name: '',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Offer Status',
    },
    cell: {
      type: 'caption',
      name: '',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Requisitie status',
    },
    cell: {
      type: 'caption',
      name: '',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Min limit',
    },
    cell: {
      type: 'text',
      value: (e) => e.minLimit,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Max limit',
    },
    cell: {
      type: 'text',
      value: (e) => e.maxLimit,
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Profile name',
    },
    cell: {
      type: 'text',
      value: (e) => e.profileName,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
